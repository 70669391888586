<template>
  <TabView>
    <TabPanel header="Kullanıcılar">
      <Users />
    </TabPanel>
    <TabPanel header="Macrolar">
      <Macros />
    </TabPanel>

  </TabView>


</template>

<script>
import Users from '@/components/Users.vue';
import Macros from '@/components/Macros.vue';
import axios from 'axios';
export default {

  name: 'DashBoardView',

  data() {
    return {
      items: [
        { label: 'Kullanıcılar', icon: 'pi pi-fw pi-user', route: '/dashboard' },
        { label: 'Macrolar', icon: 'pi pi-fw pi-user', route: '/macros' },
      ],
    };
  },
  mounted() {
    setInterval(this.checkJwtLogin, 30 * 60 * 1000);
    this.checkJwtLogin();
  },
  methods: {

    checkJwtLogin() {
      const token = localStorage.getItem('token')
      if (token) {
        axios.post('https://macroapi.sobesoft.com.tr/jwtCheck', {
          token: localStorage.getItem('token'),
        })
          .then(response => {
            console.log(response);
            if (response.status === 201) {
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/');
              this.error = 'Token geçersiz. Lütfen tekrar giriş yapınız.';
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push('/');
              this.error = 'Token geçersiz. Lütfen tekrar giriş yapınız.';
            } else {
              this.error = 'Beklenmeyen bir hata oluştu. Lütfen tekrar deneyiniz.';
            }
          });
      }

    },


  },
  components: {
    Users,
    Macros,
  }
};
</script>