<template>
  <div>
    <h1>Kullanıcı Yönetimi</h1>
    <div class="button-container">
      <Toast />
      <Button label="Yeni Kullanıcı Ekle" @click="visible = true" rounded raised />
      <Dialog v-model:visible="visible" modal header="Kullanıcı Ekle" :style="{ width: '25rem' }">
        <div class="flex align-items-center gap-3 mb-3">
          <label for="email" class="font-semibold w-6rem">Email</label>
          <InputText id="email" v-model="email" class="flex-auto" autocomplete="off" />
        </div>
        <div class="flex align-items-center gap-3 mb-5">
          <label for="password" class="font-semibold w-6rem">Şifre</label>
          <Password id="password" v-model="password" class="flex-auto" autocomplete="off" :feedback="false"
            :inputStyle="{ width: '100%' }" style="width: 100%" toggleMask />
        </div>
        <div class="button-container">
          <Button type="button" label="Vazgeç" severity="secondary" @click="visible = false"></Button>
          <div class="button-gap"></div>
          <Button type="button" label="Kaydet" @click="adduser"></Button>
        </div>
      </Dialog>
      <div class="button-gap"></div>

      <Button @click="logout" label="Çıkış Yap" severity="danger" rounded raised></Button>

    </div>

    <DataTable :value="users" tableStyle="min-width: 50rem">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-xl text-900 font-bold">Kullanıcılar</span>
        </div>
      </template>
      <Column field="email" header="Email"></Column>
      <Column header="" :style="{ width: '100px' }">
        <template #body="slotProps">
          <Button key="confirmDialog" :value="slotProps.data.id" severity="danger" label="Sil"
            @click="deleteUser(slotProps.data['_id'])" rounded raised />
        </template>
      </Column>
    </DataTable>

  </div>
</template>
<style>
.button-container {
  display: flex;
  justify-content: flex-end;

  /* Butonları aralarında boşluk bırakarak yatay olarak hizalar */
}

.button-gap {
  margin-right: 10px;
  /* Butonlar arasındaki boşluğu ayarlayabilirsiniz */
}
</style>
<script>
import axios from 'axios';
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  });
export default {
  name: 'Users',
  data() {
    return {
      users: [],
      visible: false,
      email: '',
      password: '',
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    logout() {
      this.$confirm.require({
        message: 'Çıkış yapmak istediğinizden emin misiniz?',
        header: 'Çıkış Onayı',
        acceptLabel: 'Evet',
        rejectLabel: 'Hayır',
        accept: async () => {
          const token = localStorage.getItem('token')

          axios.post('https://macroapi.sobesoft.com.tr/logout', {
            token: token
          }).then(() => {
            this.$router.push('/');
            localStorage.removeItem('token');
          });

        },
        reject: () => {
          //message here
        }
      })

    },

    fetchUsers() {
      axios.post('https://macroapi.sobesoft.com.tr/users', {
        company: localStorage.getItem('company'),
      })
        .then(response => {
          this.users = response.data["users"];

        })
        .catch(error => {
          console.error(error);
        });
    },
    adduser() {
      axios.post('https://macroapi.sobesoft.com.tr/users/register', {
        email: this.email,
        password: this.password
      })
        .then(response => {
          this.fetchUsers();
          this.visible = false
          this.$toast.add({ severity: 'success', summary: 'Kullanıcı Eklendi', life: 3000 });
        })
        .catch(error => {
          this.$toast.add({ severity: 'error', summary: 'Kullanıcı Ekleme Hatası', detail: error.response.data.message, life: 3000 });
        });
    },
    deleteUser(id) {
      this.$confirm.require({
        message: 'Kullanıcıyı silmek istediğinize emin misiniz?',
        header: 'Silme Onayı',
        acceptLabel: 'Evet',
        rejectLabel: 'Hayır',
        key: 'confirmDialog',
        accept: () => {
          axios.delete(`https://macroapi.sobesoft.com.tr/users/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          }).then(response => {
            this.fetchUsers();
            this.$toast.add({ severity: 'success', summary: 'Kullanıcı Silindi', life: 3000 });

          })
            .catch(error => {
              this.$toast.add({ severity: 'error', summary: 'Kullanıcı Silme Hatası', detail: error.response.data.message, life: 3000 });
            })
        },
        reject: () => {
          //message here
        }
      })
    },

  }
};
</script>