import { createApp } from "vue";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Tag from "primevue/tag";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import App from "./App.vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import FloatLabel from "primevue/floatlabel";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import router from "./router/index.js";
import VueCookies from "vue-cookies";
import FileUpload from "primevue/fileupload";

import "primevue/resources/themes/aura-light-blue/theme.css";
import "primeicons/primeicons.css";

createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .use(VueCookies)
  .component("ConfirmDialog", ConfirmDialog)
  .component("FileUpload", FileUpload)
  .component("Dialog", Dialog)
  .component("Toast", Toast)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("InputText", InputText)
  .component("Password", Password)
  .component("FloatLabel", FloatLabel)
  .component("Tag", Tag)
  .component("Button", Button)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("ColumnGroup", ColumnGroup)
  .component("Row", Row)
  .mount("#app");
