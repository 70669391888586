<template>
  <div class="login-container">
    <div class="login-form">
      <img src="@/assets/logo.png" alt="Company Logo">
      <h1>Sobesoft Macro Kullanıcı Sistemi</h1>
      <input type="email" placeholder="Email Address" v-model="email">
      <input type="password" placeholder="Şifre" v-model="password">
      <!-- <input type="şirket" placeholder="Şirket" v-model="company"> -->
      <Button v-on:click.prevent="login()">Login</Button>
      <div v-if="error" class="error-message">{{ error }}</div>

      <!-- <button @click="signup">SignUp</button> -->
    </div>
  </div>
</template>

<style>
.login-container {
  display: flex;
  justify-content: center;
  padding-top: 10%;
  height: 100%;
}

.login-form {
  text-align: center;
}

img {
  margin-bottom: 20px;
  height: 150px;
}

input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
</style>
<script>
import axios from 'axios';
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      company: '',
      error: ''
    };
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.checkJwtLogin()
    }
  },
  methods: {
    login() {
      axios.post('https://macroapi.sobesoft.com.tr/login', {
        email: this.email,
        password: this.password
      })
        .then(response => {
          console.log(response);
          if (response.data.token !== undefined) {
            localStorage.setItem('token', response.data.token);
            this.$router.push('/dashboard');
          } else {
            localStorage.removeItem('token');
            this.error = 'Giriş başarısız. Lütfen tekrar deneyin.'
          }
        })
        .catch(error => {
          this.error = 'Giriş başarısız. Lütfen tekrar deneyin.';
        });
    },
    signup() {
      axios.post('https://macroapi.sobesoft.com.tr/signup', {
        email: this.email,
        password: this.password,
        company: this.company
      })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    checkJwtLogin() {
      const token = localStorage.getItem('token')
      console.log()
      if (token) {
        axios.post('https://macroapi.sobesoft.com.tr/jwtCheck', {
          token: localStorage.getItem('token'),
        })
          .then(response => {
            if (response.status === 201) {
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/');
              this.error = 'Token geçersiz. Lütfen tekrar giriş yapınız.';
            }
          })
          .catch(error => {
            // Hata durumunda ekrana uyarı ver
            this.error = 'Token geçersiz. Lütfen tekrar giriş yapınız.';
          });
      }

    },
  }
}
</script>