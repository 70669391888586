<!-- <template>
  <div :key="uniqueKey">
    <h1>Macro Yönetimi</h1>
    <div class="upload-container">
      <FileUpload mode="basic" chooseLabel="Macro Yükle" name="demo[]" accept=".mcr" url="/api/upload" customUpload
        @uploader="customBase64Uploader" />
      <div class="button-gap"></div>
      <Button type="button" label="Şablon Macro İndir" @click="downloadMacro(this.macroName)"></Button>
    </div>

    <DataTable :value="macros" tableStyle="min-width: 40rem">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          <span class="text-xl text-900 font-bold">Macrolar</span>
        </div>
      </template>
      <Column field="name" header="Macro"></Column>
      <Column field="name" header="" :style="{ width: '25px' }">
        <template #body="slotProps">
          <Button key="confirmDialog" :value="slotProps.data['name']" severity="info" label="İndir"
            @click="downloadMacro(slotProps.data['name'])" rounded raised />
        </template>
      </Column>
      <Column header="" :style="{ width: '25px' }">
        <template #body="slotProps">
          <Button key="confirmDialog" :value="slotProps.data['id']" severity="danger" label="Sil"
            @click="deleteMacro(slotProps.data['id'])" rounded raised />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<style>
.upload-container {
  display: flex;
  justify-content: flex-end;
}

.button-gap {
  margin-right: 10px;
}
</style>
<script>
import axios from "axios";
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default {
  name: "Macros",

  data() {
    return {
      uniqueKey: 0,
      macros: [],
      visible: false,
      password: "",
      macroName: "",
    };
  },
  mounted() {
    this.fetchMacros();
  },
  methods: {
    async customBase64Uploader(event) {

      const formData = new FormData();
      const file = event.files[0];
      formData.append("file", file);
      axios
        .post(`https://macroapi.sobesoft.com.tr/macros/`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.fetchMacros(); // fetchMacros methodunu burada çağırıyoruz
        })
        .catch((err) => {
          console.log(err)
          this.$toast.add({
            severity: "error",
            summary: "Dosya Yükleme Hatası",
            detail: err.response.data.message,
            life: 3000,
          });
        });
    },
    deleteMacro(id) {
      axios
        .delete(`https://macroapi.sobesoft.com.tr/macros/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.fetchMacros();
          this.$toast.add({
            severity: "success",
            summary: "Macro Silindi",
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Kullanıcı Silme Hatası",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },

    downloadMacro(name) {
      axios
        .get(`https://macroapi.sobesoft.com.tr/macros/${name}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const dosya = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const dosyaURL = window.URL.createObjectURL(dosya);
          const link = document.createElement("a");
          link.href = dosyaURL;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(dosyaURL);
          this.$toast.add({
            severity: "success",
            summary: "Dosya İndirildi",
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Dosya İndirme Hatası",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },

    fetchMacros() {
      axios
        .get("https://macroapi.sobesoft.com.tr/listmacros", {
          company: localStorage.getItem("company"),
        })
        .then((response) => {
          if (response.status == 401) {
            localStorage.removeItem("token");
            this.$router.push("/");
          }
          this.macros = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script> -->

<template>
  <h1>Şuanda Yapım Aşamasındadır</h1>
</template>
